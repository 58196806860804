@import "../../assets/scss/variable.scss";


.profile-main {
    padding: 100px 15px $padding60;

    .custom-container {
        padding: 0;

        @include lg {
            padding: 0;
        }
    }

    .back-to-search {
        margin-bottom: 40px;

        a {
            color: $gray;
            font-size: $ft-h7;
            margin-left: 12px;
            text-decoration: none;
            @include trans3;

            &:hover {
                color: $primary;
            }
        }

    }

    .man-profile {
        margin-bottom: 50px;

        .left-profile {
            .row {
                // align-items: center;
            }

            .profile-image {
                width: 150px;

                @include sm {
                    margin-bottom: 10px;
                }

                img {
                    width: 120px;
                    height: 120px;
                    border-radius: 12px;
                }

            }

            .profile-content {
                width: 100%;
                padding-left: 160px;
                margin-left: -150px;

                @include sm {
                    padding-left: 10px;
                    margin-left: 0;
                }

                h3 {
                    font-size: $ft-h5;

                    @include md {
                        font-size: $ft-h6;
                    }
                }

                p {
                    font-size: $ft-h7;
                    word-break: break-word;
                    text-align: justify;
                }

                .jona-links {
                    .nav-item {
                        padding-right: 30px;

                        a {
                            font-weight: $fw700;
                            color: $primary;
                            font-size: $ft-h7;
                            padding: 0;
                            position: relative;
                            padding-left: 20px;

                            span {
                                position: absolute;
                                top: -1px;
                                left: 0;
                            }
                        }
                    }

                }
            }
        }

        .profile-btn {
            align-self: center;
            text-align: right;

            @include md {
                text-align: center;
                margin-top: 25px;
            }
        }
    }

    .general-content {
        >div {
            padding-bottom: $padding24;
            margin-bottom: $padding24;
            border-bottom: 1px dashed $light_secondary1;
        }

        h3 {
            font-size: $ft-h5;

            @include md {
                font-size: $ft-h6;
            }
        }

        .first-block {
            .day-time-lang {

                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 30px;

                >div {
                    border-right: 1px dashed $secondary;
                    padding-right: 30px;

                    &:last-child {
                        border-right: 0;
                        padding-left: 0;

                        @include lg {
                            border-right: none;
                        }

                        @include md {
                            padding-left: 0;
                        }
                    }

                    @include sm {
                        border-right: none;
                        padding-right: 0;
                    }

                }


                @include sm {

                    grid-template-columns: repeat(1, 1fr);
                }

                .days-time {
                    // width: 40%;

                    @include md {
                        // width: 45%;
                    }

                    @include sm {
                        width: 100%;
                        margin-bottom: 24px;
                    }
                }

                .facility {
                    width: 70%;

                    >div {
                        border-right: 1px dashed $secondary;
                        padding-right: 30px;

                        &:last-child {
                            padding-left: 30px;

                            @include lg {
                                border-right: none;
                            }

                            @include md {
                                padding-left: 0;
                            }
                        }

                        &:first-child {
                            @include md {
                                border-right: none;
                                padding-bottom: 24px;
                                margin-bottom: 24px;
                                border-bottom: 1px dashed #80d3cb;
                            }
                        }

                        @include md {
                            border-right: 0;
                            padding: 0;
                        }
                    }

                    @include lg {
                        width: 100%;
                        padding-bottom: 24px;
                        margin-bottom: 24px;
                        border-bottom: 1px dashed #80d3cb;
                        margin-left: 0;
                    }

                    .days-available {
                        @include md {
                            max-width: 400px;
                        }


                        .days {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 10px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            .weekdays {

                                display: flex;
                                align-items: center;

                                label {
                                    color: $gray;
                                    font-size: $ft-h5;
                                    padding-left: 12px;

                                    @include md {
                                        font-size: $ft-h6;
                                    }

                                }

                                input {
                                    height: 20px;
                                    min-width: 20px;
                                }

                                .signup-checkbox {
                                    display: flex;

                                    .form-check-input {
                                        margin-top: 5px !important;
                                    }

                                    label {
                                        color: $gray;
                                        font-size: $ft-h5;
                                        padding-left: 10px;

                                        @include md {
                                            font-size: $ft-h6;
                                        }
                                    }
                                }

                            }

                            .weektiming {
                                display: flex;
                                column-gap: 15px;

                                .weekfrom {
                                    width: 50%;
                                    display: flex;
                                    align-items: center;

                                    label {
                                        color: $gray;
                                        font-size: $ft-h5;
                                        padding-left: 10px;

                                        @include md {
                                            font-size: $ft-h6;
                                        }
                                    }

                                    .signup-select {
                                        margin-left: 15px;
                                        margin-bottom: 0;

                                        .react-dropdown-select {
                                            width: 140px;
                                            padding: $padding12 !important;
                                            height: 60px;
                                            border-radius: 12px !important;

                                            @include md {
                                                height: 48px;
                                            }

                                            .react-dropdown-select-input {
                                                max-width: 85px;
                                                font-size: $ft-h5 !important;

                                                @include sm {
                                                    max-width: 48px;
                                                }
                                            }

                                            @include sm {
                                                width: 100px;
                                            }

                                            .react-dropdown-select-content {
                                                width: 80%;
                                                font-size: $ft-h5;
                                                color: $primary;

                                                @include md {
                                                    font-size: $ft-h6;
                                                }
                                            }

                                            .react-dropdown-select-dropdown .react-dropdown-select-item {
                                                font-size: $ft-h5;
                                                color: $primary;

                                                @include md {
                                                    font-size: $ft-h6;
                                                }
                                            }

                                        }
                                    }
                                }
                            }
                        }
                    }
                }


                .lang {
                    // border-left: 1px dashed $secondary;
                    padding-left: 40px;

                    @include lg {
                        padding-left: 0;
                    }
                }
            }
        }

        ul {
            padding-left: 26px;

            li {
                font-size: $ft-h5;
                color: $gray;
                list-style: none;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    top: 8px;
                    left: -25px;
                    width: 10px;
                    height: 10px;
                    border-radius: 6px;
                    background-color: #01a796;
                }

                @include md {
                    font-size: $ft-h6;

                    &::before {
                        top: 6px;
                        width: 8px;
                        height: 8px;
                    }
                }
            }

            margin-bottom: 0;
        }

        &.review-content {
            .review-cards {
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: 1px dashed transparent;

                .card {
                    border-radius: 40px;
                    border: 1px solid $light_gray;
                    padding: 20px;
                    margin-bottom: 10px;

                    p {
                        font-size: $ft-h5;
                        font-weight: $fw500;

                        @include md {
                            font-size: $ft-h6;
                        }
                    }

                    h3 {
                        span {
                            display: inline-block;
                            margin-right: 15px;
                            img{
                                width: 48px;
                                height: 48px;
                                border-radius: 12px;
                            }
                        }
                    }

                    .card-btm {
                        display: flex;
                        justify-content: space-between;

                        .reviewrating {
                            font-weight: $fw700;
                            color: $primary;
                            position: relative;
                            padding-left: 30px;

                            @include md {
                                font-size: $ft-h6;
                            }

                            span {
                                position: absolute;
                                left: 0;
                                top: -2px;
                            }
                        }

                        .reviewdate {
                            font-size: $ft-h5;
                            font-weight: $fw500;
                            color: $gray;

                            @include md {
                                font-size: $ft-h6;
                            }
                        }
                    }
                }
            }
        }
    }


    ul.nav.nav-tabs {
        border-color: $light_secondary1;
        margin-bottom: 30px;

        li.nav-item {
            .nav-link {
                font-size: $ft-h5;
                padding: 9px $padding32;
                margin: 0;
                margin-right: $padding12;
                border-radius: 6px 6px 0px 0px;
                min-width: 240px;
                text-align: center;
                font-weight: $fw700;
                color: $gray;
                border: 0;
                cursor: pointer;

                &.active {
                    color: $primary;
                    background-color: $light_secondary2;
                }

                @include lg {
                    font-size: $ft-h6;
                    min-width: 140px;
                }

                @include md {
                    font-size: $ft-h7;
                    min-width: inherit;
                    padding: 9px $padding6;
                    margin-right: $padding6;
                }
            }
        }
    }

}