@import "../../assets/scss/variable.scss";

.profile-main {
  padding: 100px 15px $padding60;

  .custom-container {
    padding: 0;

    @include lg {
      padding: 0;
    }
  }

  .back-to-search {
    margin-bottom: 40px;

    a {
      color: $gray;
      font-size: $ft-h7;
      margin-left: 12px;
      text-decoration: none;
      @include trans3;

      &:hover {
        color: $primary;
      }
    }
  }

  .man-profile {
    margin-bottom: 50px;

    .left-profile {
      .row {
        align-items: center;
      }

      .profile-image {
        width: 150px;

        @include sm {
          margin-bottom: 10px;
          width: 100%;
        }
      }

      .profile-content {
        width: 100%;
        padding-left: 160px;
        margin-left: -150px;

        @include sm {
          padding-left: 10px;
          margin-left: 0;
        }

        h3 {
          font-size: $ft-h5;

          @include md {
            font-size: $ft-h6;
          }
        }

        p {
          font-size: $ft-h7;
          word-break: break-word;
        }

        .jona-links {
          .nav-item {
            padding-right: 30px;

            a {
              font-weight: $fw700;
              color: $primary;
              font-size: $ft-h7;
              padding: 0;
              position: relative;
              padding-left: 20px;

              span {
                position: absolute;
                top: -1px;
                left: 0;
              }
            }
          }
        }
      }
    }

    .right-profile {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: end;
      margin-top: 20px;

      span {
        font-weight: 600;
        margin-right: 10px;
      }
    }

    @include md {
      margin: 20px 0px;
    }

    .profile-btn {
      align-self: center;
      text-align: right;

      @include md {
        text-align: center;
        margin-top: 25px;
      }
    }
  }

  .general-content {
    > div {
      padding-bottom: $padding24;
      margin-bottom: $padding24;
      border-bottom: 1px dashed $light_secondary1;
    }

    h3 {
      font-size: $ft-h5;

      @include md {
        font-size: $ft-h6;
      }
    }

    .first-block {
      .day-time-lang {
        display: flex;

        @include sm {
          flex-direction: column;
        }

        .days-time {
          width: 40%;

          @include md {
            width: 45%;
          }

          @include sm {
            width: 100%;
          }
        }

        .lang {
          // border-left: 1px dashed $secondary;
          padding-left: 25px;

          @include sm {
            border-left: none;
            padding-left: 0;
            margin-top: 15px;
          }
        }
      }
    }

    ul {
      padding-left: 16px;

      li {
        font-size: $ft-h5;
        color: $gray;

        @include md {
          font-size: $ft-h6;
        }
      }

      margin-bottom: 0;
    }

    &.review-content {
      .review-cards {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 1px dashed transparent;

        .card {
          border-radius: 40px;
          border: 1px solid $light_gray;
          padding: 20px;
          margin-bottom: 10px;

          p {
            font-size: $ft-h5;
            font-weight: $fw500;

            @include md {
              font-size: $ft-h6;
            }
          }

          h3 {
            span {
              display: inline-block;
              margin-right: 15px;
            }
          }

          .card-btm {
            display: flex;
            justify-content: space-between;

            .reviewrating {
              font-weight: $fw700;
              color: $primary;
              position: relative;
              padding-left: 30px;

              @include md {
                font-size: $ft-h6;
              }

              span {
                position: absolute;
                left: 0;
                top: -2px;
              }
            }

            .reviewdate {
              font-size: $ft-h5;
              font-weight: $fw500;
              color: $gray;

              @include md {
                font-size: $ft-h6;
              }
            }
          }
        }
      }
    }
  }

  ul.nav.nav-tabs {
    border-color: $light_secondary1;
    margin-bottom: 30px;

    li.nav-item {
      .nav-link {
        font-size: $ft-h5;
        padding: 9px $padding32;
        margin: 0;
        margin-right: $padding12;
        border-radius: 6px 6px 0px 0px;
        min-width: 240px;
        text-align: center;
        font-weight: $fw700;
        color: $gray;
        border: 0;
        cursor: pointer;

        &.active {
          color: $primary;
          background-color: $light_secondary2;
        }

        @include lg {
          font-size: $ft-h6;
          min-width: 140px;
        }

        @include md {
          font-size: $ft-h7;
          min-width: inherit;
          padding: 9px $padding6;
          margin-right: $padding6;
        }
      }
    }
  }
}
