@import "../../assets/scss/variable.scss";

.user-listing {
  padding-top: 100px;

  h1 {
    font-size: $ft-h5;
    margin-bottom: 40px;
  }

  ul.nav.nav-tabs {
    margin-bottom: 20px;
  }
}
h1 {
  font-size: $ft-h5;
  margin-bottom: 40px;
}
.header {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  row-gap: 20px;

  ::placeholder {
    color: $dark_secondary4 !important;
    font-weight: $fw500 !important;
    font-size: $ft-h6 !important;
  }

  @include sm {
    justify-content: start;
  }

  .apply-btn {
    margin-left: auto;

    @include sm {
      margin: 0 auto;
    }
  }
  > div {
    margin-right: 20px;
  }

  .userfilter {
    display: flex;
    align-items: center;

    @include sm {
      width: 100%;
      margin-right: 0;
    }

    label {
      color: $dark_secondary4;
      font-weight: $fw700;
    }

    .daterange-input {
      display: inline-block;
      position: relative;
      margin-left: 15px;

      input {
        height: 40px;
        width: 242px;
        background-color: $light_gray1;
        border-radius: 6px;
        // color: $dark_secondary4 !important;
        font-weight: $fw500 !important;
        border: none;
        padding-left: 10px;
        font-size: $ft-h6 !important;
        padding-right: 45px;
      }

      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 12px;
        cursor: pointer;
      }

      .flatpickr-input {
        background-image: none !important;
      }
    }
  }

  .usertype {
    display: flex;
    align-items: center;

    label {
      color: #6e6b7b;
      font-weight: $fw700;
      margin-right: 15px;

      @include sm {
        margin-right: 10px;
      }
    }
  }

  .user-sorting {
    &:last-child {
      @include lg {
        width: 100%;
      }
    }
    .search {
      // position: relative;
      display: flex;
      background-color: $light_gray1;
      border-radius: 8px;

      svg {
        margin-left: 10px;
        height: 40px;
        width: 20px;
      }

      input {
        height: 40px;
        width: 250px;
        background-color: $light_gray1;

        @include all_center;
        border: none !important;
        font-size: $ft-h7 !important;
        font-weight: $fw200;
        padding: $padding12 !important;
      }

      input::placeholder {
        font-style: italic !important;
      }
    }
    .sort {
      position: relative;

      .react-dropdown-select {
        height: 40px;
        min-width: 140px;
        background-color: $light_gray1;
        @include all_center;
        border: none !important;
        font-size: $ft-h6 !important;
        font-weight: $fw500;
        padding: $padding12 !important;

        .react-dropdown-select-content {
          overflow: inherit;

          span {
            overflow: inherit;
          }

          .react-dropdown-select-input {
            max-width: 90px;
            width: 100%;
          }
        }

        ::backdrop {
          font-size: $ft-h6 !important;
          font-weight: $fw500;
          color: $dark_secondary4;
        }

        .react-dropdown-select-dropdown {
          width: 100%;
          left: 0;
          top: 44px;
        }
      }

      &.dropdown-limited-width {
        min-width: 120px;
        max-width: 140px;
      }
    }
    .hourly-btn {
      span {
        border: 1px solid $light_gray;
        width: 40px;
        height: 40px;
        padding: 8px 14px;
        border-radius: 100%;
        margin: 0 5px;
        cursor: pointer;

        &:first-child {
          svg {
            transform: rotate(180deg);
          }
        }

        &:hover {
          border-color: $light_primary;

          path {
            fill: $primary;
          }
        }

        &.active {
          border-color: $light_primary;

          path {
            fill: $primary;
          }
        }

        @include sm {
          width: 30px;
          height: 30px;
          padding: 0px 9px;
        }
      }
    }
  }

  .download-icon {
    cursor: pointer;
    margin-right: 0;
    display: inline-block;
    background-color: $primary;
    height: 37px;
    width: 60px;
    border-radius: 6px;
    text-align: center;
    line-height: 35px;
  }
}

.confirm-shift-table {
  // padding-top: 100px;

  .rdt_Table {
    padding-bottom: 100px;
  }

  // .confirm-shift-header {
  //     display: flex;
  //     justify-content: space-between;
  //     margin-bottom: 40px;
  //     flex-wrap: wrap;

  //     h1 {
  //         font-size: $ft-h5;
  //     }
  //     .user-filter{
  //         display: flex;
  //         align-items: center;
  //         label{
  //             color: $gray;
  //             font-weight: $fw700;
  //             margin-right: 15px;
  //         }
  //         .react-dropdown-select {
  //             height: 40px;
  //             width: 140px;
  //             background-color: $light_gray1;
  //             @include all_center;
  //             border: none !important;
  //             font-size: $ft-h6 !important;
  //             font-weight: $fw500;
  //             padding: $padding12 !important;

  //             .react-dropdown-select-content {
  //                 overflow: inherit;
  //             }

  //             ::placeholder {
  //                 color: $dark_secondary4 !important;
  //                 font-weight: $fw500 !important;
  //                 font-size: $ft-h6 !important;
  //             }

  //             &::after {
  //                 content: '';
  //                 // background: url(../../assets//images/icons/sorting.png) no-repeat;
  //                 position: absolute;
  //                 top: 11px;
  //                 right: 7px;
  //                 background-size: 50%;
  //                 width: 20px;
  //                 height: 20px;
  //             }

  //             ::backdrop {
  //                 font-size: $ft-h6 !important;
  //                 font-weight: $fw500;
  //                 color: $dark_secondary4;
  //             }

  //             .react-dropdown-select-dropdown-handle {
  //                 // display: none;
  //             }

  //             .react-dropdown-select-dropdown {
  //                 width: 100%;
  //                 left: 0;
  //                 top: 44px;
  //             }
  //         }
  //     }
  // }

  .pencil-icon {
    margin-left: 5px;
  }

  .react-dropdown-select {
    border: none !important;
    padding: 0 !important;

    ::placeholder {
      color: $primary;
      font-weight: $fw700;
      font-size: $ft-h7;
    }

    .react-dropdown-select-input,
    .react-dropdown-select-dropdown-handle {
      margin-left: 0;
    }

    .react-dropdown-select-input {
      max-width: 0;
      width: 100%;
    }

    .react-dropdown-select-dropdown {
      top: 35px;
      width: 95px;

      .react-dropdown-select-item {
        padding: 3px 5px;
      }
    }
  }
}

.edittimework-modal {
  .modal-body {
    .model-input {
      input {
        height: 40px;
        border-radius: 6px;
        background: $white;
        border: 1px solid $light_gray;
      }
    }

    #upload-box {
      .single-file {
        position: relative;
        background: #ffffff;
        border: 1px dashed #01a796;
        border-radius: 6px;
        color: #01a796;
        font-weight: 700;
        font-size: $ft-h7;
        line-height: 15px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;

        input {
          position: absolute;
          opacity: 0;
          right: 0;
          left: 0;
          height: 100%;
          width: 100%;
          cursor: pointer;
        }

        svg {
          margin-right: 6px;
        }
      }
    }

    .editworkmodal-btn {
      button {
        height: 40px;
        min-width: 140px;
        font-size: $ft-h7;
        margin-top: 40px;
      }
    }
  }
}

.no-ellipse {
  white-space: pre-wrap !important;
}

.facility-payment-detail {
  .rdt_TableBody {
    .rdt_TableRow {
      .rdt_TableCell {
        .jona {
          a {
            font-weight: 400;
            font-size: $ft-h7;
            line-height: 15px;
            color: $gray !important;
            text-decoration: none;
            @include trans3;

            &:hover {
              color: $primary !important;
            }
          }
        }
      }
    }
  }

  .rdt_TableCol_Sortable {
    opacity: 1 !important;

    span {
      opacity: 1 !important;

      .__rdt_custom_sort_icon__ * {
        opacity: 1 !important;
      }

      svg {
        opacity: 1 !important;
      }
    }
  }
}

.modal {
  .receipt-modal {
    max-width: 510px;

    .modal-content {
      background-color: transparent;
      box-shadow: none;
      border: none;

      .modal-body {
        padding: 0;

        img {
          height: 90vh;
        }
      }

      .btn-close {
        background-image: url(../../assets/images/icons/cross.png);
        position: absolute;
        right: -30px;
        top: 0;
      }
    }
  }
}

.timecard {
  display: flex;
  column-gap: 15px;
  justify-content: space-between;
  width: 130px;

  a {
    font-weight: $fw700;
  }
}

.no-data {
  display: flex;
  column-gap: 15px;
  justify-content: space-between;
  width: 170px;
}

.facility-receipt {
  .facility-receipt-des {
    display: flex;
    column-gap: 15px;
    margin-bottom: 20px;

    a {
      font-weight: $fw700;
    }
  }

  .facility-receipt-upload {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }

  a {
    font-weight: $fw700;
  }

  &.d-flex {
    p {
      margin-right: 30px;
      margin-bottom: 0;
    }
  }

  p {
    margin-bottom: 0;
  }

  overflow: hidden;
}

.total-amount {
  position: relative;

  label {
    position: absolute;
    color: $primary !important;
    font-size: $ft-h7;
    top: 12.5px;
    left: 12px;
  }

  input {
    padding-left: 20px;
  }
}

.checkbox-dropdown {
  &.react-dropdown-select {
    height: 40px;
    width: 110px;
    background-color: $light_gray1;
    border-radius: 6px;
    font-size: $ft-h6;
    font-weight: $fw500;
    @include all_center;
    border: none !important;
    font-size: $ft-h6 !important;
    font-weight: $fw500;

    .react-dropdown-select-content {
      overflow: hidden;
      max-height: 36px;
      overflow-y: auto;
      flex-wrap: wrap;

      span {
        line-height: 1;
      }

      @include scroll_vertical;
    }

    ::backdrop {
      font-size: $ft-h6 !important;
      font-weight: $fw500;
      color: $dark_secondary4;
    }

    .react-dropdown-select-dropdown {
      .checkbox {
        input {
          &.form-check-input[type="checkbox"] {
            margin-right: 5px !important;
          }
        }
      }
    }
  }
}
.adminconfirmshiftoptions {
  #table-option {
    margin-left: auto;
    margin-top: auto;
    p {
      font-weight: 400;
      min-width: 100px;
      font-size: 12px;
      line-height: 15px;
      display: block;
      color: #80798b;
      margin-bottom: 0px;
      font-size: 12px;
      text-decoration: none;
      &:hover {
        font-weight: 700;
        color: #01a796;
      }
    }
  }
}

.jobpost {
  .dwPlXY {
    display: flex;
    justify-content: space-between !important;
  }
  .rdt_Table {
    padding-bottom: 0 !important;
  }
}
.facility-payment-detail .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
