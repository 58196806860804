@import "../../assets/scss/variable.scss";


.Editjob-main {
    padding: 100px 15px $padding60;
    position: relative;

    .custom-container {
        padding: 0;

        @include lg {
            padding: 0;
        }
    }

    .back-to-search {
        margin-bottom: 40px;

        a {
            color: $gray;
            font-size: $ft-h7;
            margin-left: 12px;
            text-decoration: none;
            font-weight: $fw700;
            @include trans3;

            &:hover {
                color: $primary;
            }
        }

    }

    .man-profile {
        margin-bottom: 50px;

        .left-profile {
            .row {
                // align-items: center;
            }

            .profile-image {
                width: 150px;

                @include sm {
                    margin-bottom: 10px;
                    width: 100%;
                }

                img {
                    width: 120px;
                    height: 120px;
                    border-radius: 12px;
                }
            }

            .profile-content {
                width: 100%;
                padding-left: 160px;
                margin-left: -150px;

                @include sm {
                    padding-left: 10px;
                    margin-left: 0;
                }

                h3 {
                    font-size: $ft-h5;

                    @include md {
                        font-size: $ft-h6;
                    }
                }

                p {
                    font-size: $ft-h7;
                    word-break: break-word;
                    text-align: justify;
                }
                
                .job-description p {
                    margin-bottom: 0px !important;
                }

                .jona-links {
                    .nav-item {
                        padding-right: 30px;

                        a {
                            font-weight: $fw700;
                            color: $primary;
                            font-size: $ft-h7;
                            padding: 0;
                            position: relative;
                            padding-left: 20px;

                            span {
                                position: absolute;
                                top: -1px;
                                left: 0;
                            }
                        }
                    }

                }
            }
        }

        .profile-btn {
            align-self: center;
            text-align: right;

            @include md {
                text-align: center;
                margin-top: 25px;
            }
        }

    }

    .general-content {
        >div {
            padding-bottom: $padding24;
            margin-bottom: $padding24;
            border-bottom: 1px dashed $light_secondary1;
        }

        .general-detail {
            font-size: $ft-h5;

            &.aboutfacility {
                ul {
                    li {
                        display: block;
                    }
                }
            }

            &.general-dress {
                ul {
                    li {
                        display: block;
                    }
                }
            }

            @include md {
                font-size: $ft-h6;
            }

            ul {
                padding-left: 0;
                margin-bottom: 0;

                li {
                    list-style: none;
                    position: relative;
                    color: $gray;
                    display: flex;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    margin-bottom: 24px;

                    span {
                        color: $primary;
                        font-weight: $fw700;
                        display: inline-block;
                        margin-right: 5px;
                    }

                    a {
                        color: $gray;
                        text-decoration: none;
                        @include trans3;

                        &:hover {
                            color: $secondary;
                        }
                    }

                    .pencilicon {
                        // margin-left: 5px;
                        padding-left: 10px;
                        cursor: pointer;
                    }

                    .includes {
                        width: 100%;
                        font-size: 12px;
                        margin: -5px 0 0 120px;

                        @include md {
                            margin-left: 115px;
                        }
                    }
                }
            }

            &.general-dress {
                ul {
                    li {
                        &:last-child {
                            margin-top: 0;
                        }
                    }
                }
            }

            &.aboutfacility-photo {
                h3 {
                    font-size: $ft-h5;
                    margin-bottom: 20px;
                }

                .facility-images {

                    .img {
                        display: flex;
                        gap: 12px;
                        flex-wrap: wrap;

                        img {
                            width: 120px;
                            height: 120px;
                            border-radius: 12px;
                            border: 0.5px solid $light_secondary1;
                        }
                    }
                }
            }

        }

        &.review-content {
            .review-cards {
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: 1px dashed transparent;

                .card {
                    border-radius: 40px;
                    border: 1px solid $light_gray;
                    padding: 20px;
                    margin-bottom: 10px;

                    p {
                        font-size: $ft-h5;
                        font-weight: $fw500;

                        @include md {
                            font-size: $ft-h6;
                        }
                    }

                    h3 {
                        font-size: $ft-h5;

                        span {
                            display: inline-block;
                            margin-right: 15px;

                            img {
                                width: 48px;
                                height: 48px;
                                border-radius: 12px;
                            }
                        }
                    }

                    .card-btm {
                        display: flex;
                        justify-content: space-between;

                        .reviewrating {
                            font-weight: $fw700;
                            color: $primary;
                            position: relative;
                            padding-left: 30px;

                            @include md {
                                font-size: $ft-h6;
                            }

                            span {
                                position: absolute;
                                left: 0;
                                top: -2px;
                            }
                        }

                        .reviewdate {
                            font-size: $ft-h5;
                            font-weight: $fw500;
                            color: $gray;

                            @include md {
                                font-size: $ft-h6;
                            }
                        }
                    }
                }
            }
        }

    }


    ul.nav.nav-tabs {
        border-color: $light_secondary1;
        margin-bottom: 30px;

        li.nav-item {
            .nav-link {
                font-size: $ft-h5;
                padding: 9px $padding32;
                margin: 0;
                margin-right: $padding12;
                border-radius: 6px 6px 0px 0px;
                min-width: 240px;
                text-align: center;
                font-weight: $fw700;
                color: $gray;
                border: 0;
                cursor: pointer;

                &.active {
                    color: $primary;
                    background-color: $light_secondary2;
                }

                @include lg {
                    font-size: $ft-h6;
                    min-width: 140px;
                }

                @include md {
                    font-size: $ft-h7;
                    min-width: inherit;
                    padding: 9px $padding6;
                    margin-right: $padding6;
                }
            }
        }
    }


}

.editPay-modal {
    .pay-select {
        margin-bottom: 40px;

        label {
            font-size: $ft-h7;
            color: $gray;
        }
    }
}

.editexp-modal {
    .experience-select {
        margin-bottom: 40px;

        label {
            font-size: $ft-h7;
            color: $gray;
        }
    }
}

.editCliType-modal {
    .clinicianType-select {
        margin-bottom: 40px;

        label {
            font-size: $ft-h7;
            color: $gray;
        }
    }
}

.editFaciType-modal {
    .facilityType-select {
        margin-bottom: 40px;

        label {
            font-size: $ft-h7;
            color: $gray;
        }
    }

}

.editLocation-modal {

    .jobAddress1-input,
    .jobAddress2-input,
    .state-select,
    .zipCode-input {
        margin-bottom: 20px;

        label {
            font-size: $ft-h7;
            color: $gray;
        }

    }
}

.editJobDes-modal {
    .jobDes-input {
        margin-bottom: 40px;

        label {
            font-size: $ft-h7;
            color: $gray;
        }
    }
}

.thirdmodal {
    .react-dropdown-select {
        width: 90px;
    }
}

.fourthmodal {
    .react-dropdown-select {
        width: 82px;
        padding: 10px !important;
    }
}

// .spinner-border {
//     position: absolute;
//     left: 50%;
//     top: 50%;
// }

.modal-dialog {
    .modal-body {
        label {
            font-size: 12px;
            color: #80798B;
            line-height: 15px;
        }
    }
}

.job-date-picker {
    font-size: 12px;
    color: #80798B;
    line-height: 15px;

    .react-datepicker {
        box-shadow: none;
        width: 100%;

        .react-datepicker__month-container {
            width: 100%;
        }
    }

    .react-datepicker__header__dropdown,
    .react-datepicker__navigation {
        display: none;
    }

    .react-datepicker__day {
        color: $gray;
        border-radius: 40px;
        line-height: 40px;
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
        width: 13.2857143%;
        max-width: 14.2857143%;
        border-radius: 0;
        margin: 0;
    }

    .react-datepicker__day--outside-month,
    .react-datepicker__day--disabled {
        color: $light_gray;
    }

    .react-datepicker__day:hover,
    .react-datepicker__day--in-range,
    .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected {
        border-radius: 40px;
        color: $white;
        margin-top: 1px;
    }

    .react-datepicker__day--in-range {
        border-radius: 0;
        margin-top: 1px;
        color: $white;

        &:hover {
            border-radius: 0;
        }
    }

    .react-datepicker__day--range-start {
        border-radius: 40px 0 0 40px;

        &:hover {
            border-radius: 40px 0 0 40px;
        }
    }

    .react-datepicker__day--range-end {
        border-radius: 0 40px 40px 0;

        &:hover {
            border-radius: 0 40px 40px 0;
        }
    }

    .react-datepicker__day--keyboard-selected.react-datepicker__day--range-start.react-datepicker__day--range-end.react-datepicker__day--in-range {
        border-radius: 40px;
    }
}

.days-available {
    .weektiming {
        .weekfrom {
            .signup-select {
                label {
                    display: none;
                }

                .form-group {
                    margin-bottom: 0;
                }

            }
        }
    }

    .react-datepicker__input-container .form-control {
        background-image: none !important;
    }

    .dashed-border {
        margin-top: 6px !important;
        align-items: center;
        padding: 0;
    }
}

.applicant-btn {
    .icon-group {
        align-items: center;
    }

    h3 {
        margin-bottom: 0;
    }

    .text-danger {
        font-size: $ft-h5;
        font-weight: $ft-h7;
    }
}