@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

//color variable
$primary: #382768;
$primary1: #503895;
$light_primary: #9688bf;
$light_primary1: #50389526;
$light_primary2: #32325d;
$secondary: #01a796;
$light_secondary: #e6f6f4;
$light_secondary1: #80d3cb;
$light_secondary2: #f2f8f8;
$dark_secondary: #019384;
$dark_secondary1: #a5dfd9;
$dark_secondary3: #01a79626;
$dark_secondary4: #6e6b7b;
$info: #01aed4;
$dark_info: #029abc;
$light_info: #8fc5d1;
$light_info1: #f7fbfa;
$gray: #80798b;
$light_gray: #e2e3e4;
$light_gray1: #f8f8f8;
$light_gray2: #cacaca;
$light_gray3: #f1f3f5;
$danger: #e55252;
$dark_danger: #e20303;
$light_danger: #eb7b7b;
$black: #000000;
$white: #ffffff;
$danger: #ea5455;
$green: #28c76f;
$red: #e62626;
$warning: #ff9f43;

$font-Montserrat: "Montserrat", sans-serif;
$font-Inter: "Inter", sans-serif;

$slider-width: 50px;
$slider-height: 25px;
$slider-knob-size: 20px;
$slider-background-color: #a19d9d;
$slider-checked-color: #24742e;
$slider-transition: 0.4s;

/* Font Styles|Variable */
$fw200: 200;
$fw300: 300;
$fw400: 400;
$fw500: 500;
$fw600: 600;
$fw700: 700;
$fw800: 800;

//positions
$top: top;
$bottom: bottom;
$left: left;
$right: right;

//fontsize
$ft-h1: 40px;
$ft-h2: 28px;
$ft-h3: 24px;
$ft-h4: 20px;
$ft-h5: 18px;
$ft-h6: 14px;
$ft-h7: 12px;

//padding
$padding2: 2px;
$padding6: 6px;
$padding12: 12px;
$padding24: 24px;
$padding32: 32px;
$padding48: 48px;
$padding60: 60px;
$padding120: 120px;

// responsive class
// Small tablets and large smartphones (landscape view)

$screen-sm-max: 575px;
// Small tablets (portrait view)
$screen-md-max: 767px;
// Tablets and small desktops
$screen-lg-max: 991px;
// Semi Large tablets and desktops
$screen-xl-max: 1199px;
// Large tablets and desktops
$screen-xxl-max: 1359px;
// Extra Large tablets and desktops
$screen-xxxl-max: 1499px;
// Extra Large tablets and desktops
$screen-xxll-max: 1699px;

$mediaSizes: (
  "xs": 320px,
  "xsss": 400px,
  "sm": 576px,
  "mid": 640px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
  "xxl": 1500px,
  "cuxxl": 1750px,
);

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

// Semi Large devices
@mixin lg {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

// large devices
@mixin xl {
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}

// Extra large devices
@mixin xxl {
  @media (max-width: #{$screen-xxl-max}) {
    @content;
  }
}

// Double Extra large devices
@mixin xxxl {
  @media (max-width: #{$screen-xxxl-max}) {
    @content;
  }
}

// Double Extra large devices
@mixin xxll {
  @media (max-width: #{$screen-xxll-max}) {
    @content;
  }
}

// mediaSizes
$gloabal_width: 1920px;
$mediaSizes: (
  "xs": 320px,
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
  "xxl": 1500px,
);
.container {
  @media (min-width: map-get($mediaSizes, "xxl")) {
    max-width: $gloabal_width !important;
  }
}
@mixin box_shadow_com {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.2);
}
@mixin trans3 {
  transition: 0.3s ease-in-out;
}
@mixin trans5 {
  transition: 0.5s ease-in-out;
}
@mixin all_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

//scroll
@mixin scroll_horizontal {
  &::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: $white;
  }

  &::-webkit-scrollbar {
    height: 0.375rem;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    background-color: rgba($primary, 1);
  }
}

@mixin scroll_vertical {
  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: $light_secondary2;
  }

  &::-webkit-scrollbar {
    width: $padding6;
    background-color: $light_secondary2;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba($secondary, 1);
  }
}
